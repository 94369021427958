import { React, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import inupic from "../../images/inu.jpg";
import Divider from "@mui/material/Divider";
import { OtherApplyData } from "./BaseOfAnzan.js";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ScrollTop from "../../helper/ScrollTop.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { addDays } from "date-fns";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles(() => ({
  nomalButton: {
    background: "#1920eb",
  },
  activeButton: {
    variant: "contained",
  },
}));

const Other_2 = (props) => {
  const { currentState, loading, setCurrentState } = useContext(OtherApplyData);
  // console.log("currentState = " + JSON.stringify(currentState));
  const [num, setNum] = useState(0);
  const [value, onChange] = useState(new Date());
  const [selected_year, Setselected_year] = useState("");
  const [selected_month, Setselected_month] = useState("");
  const [selected_day, Setselected_day] = useState("");
  const [selected_time, Setselected_time] = useState("");
  const [timezone, setTimezone] = useState(["9", "11", "13", "15"]);
  const [activeButton, setActiveButton] = useState("");
  const classes = useStyles();
  const [selectedButton, setSelectedButton] = useState(false);
  const [D, setD] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const receive = ["拝殿に上がり受取", "社務所で受取", "郵送で受取"];

  const DClose = () => {
    setD(false);
  };

  const DOpen = () => {
    console.log("deleteDOpen was called");
    setD(true);
  };
  // console.log("currentState =  " + JSON.stringify(currentState));

  const Select_handla = (value) => {
    const year = value.getFullYear();
    const month = value.getMonth();
    const day = value.getDate();
    Setselected_year(Number(year));

    Setselected_month(Number(month) + 1);
    console.log("month -> " + (Number(month) + 1));

    Setselected_day(Number(day));
    console.log(day + " set");
    // SearchTime(Number(day))
  };

  //選択した時間を取得し、そのボタンの色を変える
  const clickedButtonHandler = (index, time) => {
    setActiveButton(index);
    Setselected_time(time);
    setSelectedButton(true);
    console.log(time + "時を選択！");
  };

  const onSubmit = (action) => {
    setSubmitClicked(true);
    if (action === "back") {
      props.handleBack();
    }

    if (selectedButton == true && selected_day !== "") {
      console.log("onSubmit was called");
      const data = {
        year: selected_year,
        month: selected_month,
        day: selected_day,
        time: selected_time,
      };
      setCurrentState({ ...currentState, EasyApply2: data });
      console.log("currentState =  " + JSON.stringify(currentState));

      if (action === "next") {
        props.handleNext();
      }
    } else {
      //選択するように促すダイアログ
      DOpen();
      console.log("-------選択されていません--------");
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      textAlign={"left"}
    >
      <ScrollTop />
      <Card sx={{ width: "90%" }}>
        <CardContent>
          <Grid>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              時間と戌(いぬ)の日
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
              ・ご祈祷は約30分です。祭事の都合によりご予約した日時が厳しい場合は、神社から再度ご連絡します。
            </Typography>
            <Typography sx={{ mt: 1.1, fontSize: 15 }} variant="body2">
              ・犬は多産でお産が軽いことから古くから安産の象徴とされてきました。戌の日を参考にご選択ください。
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ height: 30 }}></Box>
      {/* <Typography sx={{ m: 1.1, fontSize: 15 }} variant="body2">
        赤い文字は大安の日です。
      </Typography>

      <img src={inupic} alt="inunohi" style={{ width: "90%" }} /> */}

      <Box sx={{ height: 20 }}></Box>
      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ffa94d", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={2}>
            日にちを選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      {submitClicked === true && selected_day === "" && (
        <Typography sx={{ color: "red", fontSize: 16, fontweight: "bold" }}>
          選択されていません
        </Typography>
      )}
      {selected_day !== "" && (
        <Typography
          sx={{ fontSize: 16, backgroundColor: "#f8c9a0", fontWeight: "bold" }}
          p={2}
        >
          選択した日：{selected_month}月 {selected_day}日
        </Typography>
      )}

      <Box sx={{ height: 10 }}></Box>

      <Card sx={{ width: "90%" }}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
          // backgroundColor="#FFD36E"
        >
          <Box sx={{ height: 20 }}></Box>

          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            ※ご予約は2日後からになります
          </Typography>
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
          <StaticDatePicker
            Picker
            value={value}
            displayStaticWrapperAs="desktop"
            toolbarTitle="日付選択"
            toolbarFormat="MM月dd日"
            disablePast={true}
            showToolbar={false}
            onChange={(newValue) => {
              onChange(newValue);
              Select_handla(newValue);
              console.log("selected_day: " + newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            minDate={addDays(new Date(), 2)}
          />
        </LocalizationProvider>

        <Box sx={{ height: 20 }}></Box>
      </Card>

      <Box sx={{ height: 20 }}></Box>
      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 20 }}></Box>

      <Grid>
        <Grid container justify="flex-start" alignItems="flex-start">
          <CheckCircleOutlineIcon
            style={{ color: "#ffa94d", fontSize: 30 }}
          ></CheckCircleOutlineIcon>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }} ml={2}>
            時間を選択してください
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }}></Box>

      {submitClicked === true && selected_time == "" && (
        <Typography sx={{ color: "red" }}>時間が選択されていません</Typography>
      )}
      {selected_time !== "" && (
        <Typography
          sx={{ fontSize: 16, backgroundColor: "#f8c9a0", fontWeight: "bold" }}
          p={2}
        >
          選択した時間：{selected_time}時
        </Typography>
      )}

      {timezone.length > 1 && (
        <Grid>
          <Box sx={{ height: 20 }}></Box>
          {timezone.map((time, index) => (
            <Grid key={index}>
              <Button
                name={`name.${index}`}
                style={{
                  backgroundColor:
                    activeButton === `${index}` ? "#f8a540" : "#ffffff",
                  color: activeButton === `${index}` ? "#ffffff" : "#000000",
                  width: "250px",
                  minHeight: "40px",
                }}
                variant={"outlined"}
                onClick={() => clickedButtonHandler(`${index}`, time)}
              >
                <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                  {time}時〜
                </Typography>
              </Button>
              <Box sx={{ height: 10 }}></Box>
            </Grid>
          ))}
        </Grid>
      )}
      <Box sx={{ height: 40 }}></Box>

      <Divider style={{ width: "100%" }} />
      <Box sx={{ height: 20 }}></Box>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        textAlign={"center"}
      >
        <Button
          sx={{ width: "60%", height: 40 }}
          variant="contained"
          onClick={() => onSubmit("next")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            内容の確認へ
          </Typography>
        </Button>
        <Box sx={{ height: 20 }}></Box>
        <Button
          variant="contained"
          sx={{
            width: "60%",
            height: 40,
            backgroundColor: "#ece9e9f2",
            color: "black",
          }}
          onClick={() => onSubmit("back")}
        >
          <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
            もどる
          </Typography>
        </Button>
      </Grid>

      <Box sx={{ height: 120 }}></Box>

      <Dialog onClose={DClose} open={D}>
        <DialogTitle>選択されていません</DialogTitle>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          textAlign={"center"}
        >
          <Button variant="contained" color="inherit" onClick={() => DClose()}>
            戻る
          </Button>
          <Box sx={{ height: 20 }}></Box>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Other_2;
