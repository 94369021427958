import { React, useLayoutEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

//もしprayedがfalseなら予定を表示させる

const History = (props) => {
  const apply_num = props.apply_num;
  const num = props.values[0]["num"];
  const date = props.values[0]["time"];
  console.log("date: " + JSON.stringify(date));
  const dictionary = props.values[0]["content"];
  const type = props.values[0]["type"];
  let type_text = "";
  if (type == "apply3" || type == "apply4" || type == "apply5") {
    type_text = props.values[0]["type_text"];
  }
  // console.log("dictionary-> "+ JSON.stringify(dictionary));
  let values = "";

  for (let i = 0; i < num; i++) {
    if (i === 0) {
      values = [dictionary[`person.${i}`]];
    } else {
      values.push(dictionary[`person.${i}`]);
    }
  }

  console.log("values" + JSON.stringify(values));

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={"center"}
      sx={{ width: "100%" }}
    >
      {type == "apply1" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      日時
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {date.year}年{date.month}月{date.day}日{date.time}時〜
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      人数
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {num}名
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            名前{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.name} 様
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            祈願１{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.pray1}{" "}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            祈願2{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.pray2}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            初穂料{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.money}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}

      {type == "apply2" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      日時
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {date.year}年{date.month}月{date.day}日{date.time}時〜
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      人数
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {num}名
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            名前{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.name} 様
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            内容
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            安産祈願
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            初穂料{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            壱万円
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}

      {type == "apply3" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      日時
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {date.year}年{date.month}月{date.day}日{date.time}時〜
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      人数
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {num}名
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            名前{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.name} 様
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            内容
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {type_text}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            初穂料{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            壱万円
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}

      {type == "apply4" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      日時
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {date.year}年{date.month}月{date.day}日{date.time}時〜
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      人数
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {num}名
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            名前{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.name} 様
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            内容
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            車のご祈祷
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            初穂料{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {type_text}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}

      {type == "apply5" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      日時
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {date.year}年{date.month}月{date.day}日{date.time}時〜
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      人数
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                      {num}名
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            名前{" "}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {value.name} 様
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          内容
                        </TableCell>
                        <TableCell component="th" scope="row">
                          出張祭典
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          場所
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {type_text}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          初穂料{" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          壱万円
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}

      {type == "apply6" && (
        <Grid style={{ minWidth: "250px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    日時
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {date.year}年{date.month}月{date.day}日{date.time}時〜
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    人数
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {num}名
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ height: 20 }}></Box>
          {values !== "" &&
            values.map((value, i) => (
              <Grid key={i}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          代表者
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {value.name} 様
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          内容
                        </TableCell>
                        <TableCell component="th" scope="row">
                          厄払い
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          初穂料{" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {value.money}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ height: 10 }}></Box>
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default History;
